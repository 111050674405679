import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import StaticImg from "../../../images/static-banner-1.jpg"
import StaticImg2 from "../../../images/static-banner-2.jpg"
import StaticImg3 from "../../../images/static-banner-3.jpg"
import ReactMarkdown from "react-markdown"
import GoogleReviews from "../../../images/google-reviews.png"
import "./NewsBanner.scss"
import { Reviews_Page_Url } from "@Components/common/site/constants"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import GetURL from "@Components/common/site/get-url"
import axios from "axios"
import moment from "moment"
import wordsToNumbers from "words-to-numbers"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import {token} from "@Components/forms/api/Api"

const NewsBanner = props => {
  const [isOpen, setIsOpen] = useState(false)
  const toggling = () => setIsOpen(!isOpen)
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false)
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data"
        }
      }) // could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/stb-google-reviews/google-review-reviews`
    getitems(url)
    setClient(true)
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="news-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <picture>
            {/* <source media="(min-width: 992px)" srcSet={props.Banner_Image?.url} />
						<source media="(min-width: 768px)" srcSet={props.Banner_Image?.url} />
						<img src={props.Banner_Image?.url} alt={props.Banner_Image?.alternativeText} className="banner-img" /> */}
            <ImageTransform
              imagesources={props?.Banner_Image?.url}
              renderer="srcSet"
              imagename="blog.Banner_Image.detail"
              attr={{
                alt: props.Banner_Image?.alternativeText
                  ? props.Banner_Image?.alternativeText + " - DNG Estate Agents"
                  : props?.Banner_Title + " - DNG Estate Agents",
                className: "banner-img",
              }}
              imagetransformresult={props.processedImages}
              id={props.id}
            ></ImageTransform>
          </picture>
          <Container className="banner-wrap">
            <Row>
              <Col sm="8">
                <div className="banner-content">
                  <p className="date-para">
                    {moment(props?.Date).format("DD MMMM YYYY")} /{" "}
                    {props?.Category?.map((list, index) => (
                      <>
                        {list.Category_Name}
                        {index === props?.Category?.length - 1 ? "" : ", "}
                      </>
                    ))}
                  </p>
                  {props?.Banner_Title && (
                    <motion.h1 variants={contentItem}>
                      {props?.Banner_Title}
                    </motion.h1>
                  )}
                  {props?.Banner_Content && (
                    <motion.div variants={contentItem}>
                      <ReactMarkdown
                        source={props?.Banner_Content}
                        allowDangerousHtml
                      />
                    </motion.div>
                  )}
                  {/* {JSON.stringify(props.Cta_Links)} */}
                  <motion.div
                    className="d-none d-lg-flex"
                    variants={contentItem}
                  >
                    {props.Cta_Links?.CTA_Btn_Label1 &&
                      props.Cta_Links?.HashLink1 && (
                        <a onClick={props.executeScroll} className="btn">
                          {props.Cta_Links?.CTA_Btn_Label1}
                        </a>
                      )}
                    {props.Cta_Links?.CTA_Btn_Label1 &&
                      props.Cta_Links?.CTA_Btn_Url1?.Alias && (
                        <GetURL
                          class="btn"
                          label={props.Cta_Links?.CTA_Btn_Label1}
                          alias={props.Cta_Links?.CTA_Btn_Url1?.Alias}
                        />
                      )}
                    {props.Cta_Links?.CTA_Btn_Label2 &&
                      props.Cta_Links?.CTA_Btn_Url2?.Alias && (
                        <GetURL
                          class="btn outline-btn"
                          label={props.Cta_Links?.CTA_Btn_Label2}
                          alias={props.Cta_Links?.CTA_Btn_Url2?.Alias}
                        />
                      )}
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default NewsBanner
